import React, {useEffect, useRef, useState} from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Header } from "../../components/Header/Header";
import { Link } from "react-router-dom";
import { Title } from "../../components/Title/Title";
import { Text } from "../../components/Text/Text";
import InputMask from 'react-input-mask';
import axios from "axios";

import './Login.scss'

export const Login = ({ withBg, setToken }) => {
  const timerRef = useRef(null);
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('');
  const [loginConfirm, setLoginConfirm] = useState(false);
  const [showResendButton, setShowResendButton] = useState(false);
  const [codeInputs, setCodeInputs] = useState(['', '', '', '']);
  const [error, setError] = useState(null);
  const apiLogin = 'https://backend.respublikapark.com.ua/api/login';
  const apiLoginConfirm = 'https://backend.respublikapark.com.ua/api/login-confirmation';
  let phoneNumberWithoutFormatting;
  const token = {
    "token": {
      "accessToken": {
        "name": "loyalty",
        "abilities": [
          "*"
        ],
        "tokenable_id": 25301,
        "tokenable_type": "App\\Models\\User",
        "updated_at": "2023-06-30T11:58:31.000000Z",
        "created_at": "2023-06-30T11:58:31.000000Z",
        "id": 26000
      },
      "plainTextToken": "26000|N072y4uL5b16wuKgHhKKYXnydAbi12lBtBDG0DJX"
    }
  }

  const handleAmountChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleSubmit = () => {
    phoneNumberWithoutFormatting = phoneNumber.replace(/[\s()-]/g, '');

    setFormattedPhoneNumber(phoneNumberWithoutFormatting);

    axios.post(apiLogin, {
      phone: phoneNumberWithoutFormatting
    }).then(() => {
      setLoginConfirm(true);
      startTimer();
    }).catch(error => {
      setError(error.response.data.message);
    });
  };

  const handleResentCode = () => {
    clearInterval(timerRef.current);
    setShowResendButton(false);
    handleSubmit();
  }

  const startTimer = () => {
    let seconds = 59;

    const updateTimerDisplay = () => {
      const formattedTime = seconds < 10 ? `00:0${seconds}` : `00:${seconds}`;

      if (timerRef.current && timerRef.current instanceof HTMLElement) {
        timerRef.current.textContent = formattedTime;
      }
    };

    updateTimerDisplay();

    const interval = setInterval(() => {
      seconds--;

      if (seconds >= 0) {
        updateTimerDisplay();
      }

      if (seconds <= 0) {
        clearInterval(interval);
        setShowResendButton(true);

        if (timerRef.current && timerRef.current instanceof HTMLElement) {
          timerRef.current.textContent = '00:00';
        }
      }
    }, 1000);
  };


  const handleConfirmSubmit = () => {
    clearInterval(timerRef.current);
    const code = codeInputs.join('');
    axios.post(apiLoginConfirm, {
      phone: formattedPhoneNumber,
      code: code
    }).then((response) => {
      // console.log(response.data.token)
      setToken(response.data.token)
      const auth = JSON.parse(localStorage.getItem("authToken"));
      const plainTextToken = auth.value.plainTextToken.split("|")[1];
      axios.get(
        "https://backend.respublikapark.com.ua/api/user/e-parking/active-session",
        {
          headers: {
            Authorization: `Bearer ${plainTextToken}`,
          },
        }).then(response => {
        console.log(response.data.length)
        response.data.status === 10 && navigate('/payment-success');
        response.data.status === 20 && navigate('/charging');
        response.data.status === 50 && navigate('/');
        response.data.length === 0 && navigate("/payment");
      });

    }).catch(error => {
      setError(error.response.data.message);
    });
  };

  const handleCodeInput = (index, value) => {
    if (value.length > 1) {
      value = value.slice(0, 1);
    }

    const newCodeInputs = [...codeInputs];
    newCodeInputs[index] = value;
    setCodeInputs(newCodeInputs);

    if (value && index < 3) {
      document.getElementById(`code-input-${index + 1}`).focus();
    }
  };

  useEffect(() => {
    withBg(true);
  }, [withBg]);

  useEffect(() => {
    if (codeInputs.every(input => input !== '')) {
      handleConfirmSubmit();
    }
  }, [codeInputs]);

  return (
    <div className="content">
      <Header />
      {!loginConfirm &&
        <>
          <Link to="/" className="button-back">
            <img src="/images/ico-back.svg" alt="" />
            <span>Назад</span>
          </Link>
          <Title title={'Введіть ваш мобільний номер'} />
          <Text text={'Відправимо вам SMS з кодом для авторизації.'} />
          <div className="input-wrap input-wrap-phone">
            <label>Ваш мобільний номер</label>
            <div className="input">
              <div className="input-container">
                <InputMask
                  mask="+380 (99) 999-99-99"
                  maskChar=" "
                  value={phoneNumber}
                  onChange={handleAmountChange}
                  placeholder="Ваш мобільний номер"
                />
              </div>
            </div>
          </div>
          <button className="button" onClick={handleSubmit}>Далі</button>
        </>
      }
      {loginConfirm &&
        <>
          <div className="button-back" onClick={() => setLoginConfirm(false)}>
            <img src="/images/ico-back.svg" alt="" />
            <span>Назад</span>
          </div>
          <Title title={'Введіть код з SMS'} />
          <Text text={'для підтверждення вашого мобільного номеру.'} />
          <div className="inputs">
            {codeInputs.map((input, index) => (
              <input
                key={index}
                id={`code-input-${index}`}
                type="number"
                value={input}
                onChange={(e) => handleCodeInput(index, e.target.value)}
              />
            ))}
          </div>
          <div className="confirm-wrap">
            <div className="confirm-text">Не надійшов код?</div>
            <div className="confirm-text">Надіслати код через <span ref={timerRef}></span></div>
          </div>
            {showResendButton && <button className="button" onClick={handleResentCode}>Надіслати код повторно</button>}

          {/*{codeInputs.every(input => input !== '') && handleConfirmSubmit()}*/}
        </>
      }
    </div>
  );
};

Login.propTypes = {
  withBg: PropTypes.func.isRequired
};
