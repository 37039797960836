import React from 'react';
import PropTypes from "prop-types";

import "./Text.scss";

export const Text = ({text}) => {
  return (
    <p className="text">{ text }</p>
  );
};

Text.propTypes = {
  text: PropTypes.string.isRequired,
};