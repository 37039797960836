import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import "./AndroidBanner.scss"

export const AndroidBanner = ({ closeBanner, appUrl }) => {
  return (
    <div className="banner">
      <img src="/images/ico-close.svg" alt="close icon" className="banner-close" onClick={closeBanner}/>
      <div className="banner-content">
        <img src="/images/banner-logo.svg" alt="Logo Banner" className="banner-image"/>
        <div className="banner-text">Сплачуй за заряджання через мобільний додаток</div>
      </div>
      <div className="banner-button">
        <Link className="button" to={appUrl}>
          Встановити
        </Link>
      </div>
    </div>
  );
};

AndroidBanner.propTypes = {
  closeBanner: PropTypes.func.isRequired,
  appUrl: PropTypes.string.isRequired
};