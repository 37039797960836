import React, { useEffect, useState } from "react";
import { AndroidBanner } from "../../components/AndroidBanner/AndroidBanner";
import { Header } from "../../components/Header/Header";
import { Title } from "../../components/Title/Title";
import { Text } from "../../components/Text/Text";
import { Link } from "react-router-dom";
import { isAndroid, isMobile } from "react-device-detect";
import PropTypes from "prop-types";

import "./Home.scss";

export const Home = ({ withBg, ac, dc, authToken }) => {
  const [showBanner, setShowBanner] = useState(false);
  const [isAppInstalled, setIsAppInstalled] = useState(false);
  const [appUrl, setAppUrl] = useState('');

  const closeBanner = () => {
    setShowBanner(false);
  };

  useEffect(() => {
    withBg(true);
  }, [withBg]);

  useEffect(() => {
    if (isMobile && isAndroid) {
      const appPackage = "ua.com.respublikapark";
      setAppUrl(`https://play.google.com/store/apps/details?id=${appPackage}`)

      // Перевірка наявності встановленого застосунку
      const checkAppInstalled = () => {
        const timeout = setTimeout(() => {
          setShowBanner(true);
          setIsAppInstalled(false);
        }, 3000);

        if (navigator && navigator.getInstalledRelatedApps) {
          navigator
            .getInstalledRelatedApps()
            .then((relatedApps) => {
              clearTimeout(timeout);
              setShowBanner(true);
              setIsAppInstalled(
                relatedApps.some(
                  (app) => app.packageId === appPackage
                )
              );
            })
            .catch(() => {
              clearTimeout(timeout);
              setShowBanner(true);
              setIsAppInstalled(false);
            });
        } else {
          clearTimeout(timeout);
          setShowBanner(true);
          setIsAppInstalled(false);
        }
      };

      checkAppInstalled();
    }

  }, []);

  return (
    <>
      {showBanner && (
        <AndroidBanner
          closeBanner={closeBanner}
          appUrl={appUrl}
        />
      )}

      <div className="content">
        <Header />
        <Title title="Інформація про електрозарядку" />
        <div className="content-wrap">
          <Text
            text="Ви можете зарядити свій автомобіль на нашому паркінгу в зонах P7 та P1. Передплачуйте в додатку та підключайте "
          />
          {(ac && dc) &&
            <div className="tariffs">
              <div className="tariffs-head">ТАРИФИ:</div>
              <ul>
                <li>Звичайна зарядка (AC):
                  <span>{ac / 100}</span>
                  <span>₴/кВт</span>
                </li>
                <li>Швидка зарядка (DC):
                  <span>{dc / 100}</span>
                  <span>₴/кВт</span>
                </li>
              </ul>
            </div>
          }
          <img src="/images/home-image.png" alt="charging" />
          <Link className="button" to={authToken ? "/payment" : "/login"}>
            До оплати
          </Link>
        </div>
      </div>
    </>
  );
};

Home.propTypes = {
  withBg: PropTypes.func.isRequired,
  ac: PropTypes.number.isRequired,
  dc: PropTypes.number.isRequired,
  authToken: PropTypes.bool.isRequired
};
