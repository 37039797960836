import React, { useEffect } from "react";
import { Header } from "../../components/Header/Header";
import { Title } from "../../components/Title/Title";
import { Text } from "../../components/Text/Text";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

export const PaymentError = ({ withBg }) => {

  useEffect(() => {
    withBg(true)
  }, [withBg]);

  return (
    <div className="content">
      <Header />
      <Title title="Оплата не пройшла" />
      <div className="content-wrap">
        <Text text={
          'Lorem ipsum dolor sit amet consectetur. Iaculis netus sed nulla posuere ' +
          'vel aliquet sed. Habitant lectus non id cum aliquam vel. Massa semper quis donec ' +
          'purus amet. Urna aliquam amet cras lorem lobortis. In aliquam nunc elementum a.' +
          ' Nisl hendrerit vulputate risus neque faucibus ' +
          'ipsum ultrices cras enim. Enim a netus egestas dignissim est.' +
          ' Eros id libero nunc interdum ut.'
        } />
        <img src="/images/payment-error.png" alt="error" />
        <Link className="button" to="/payment">Повернутись назад</Link>
      </div>
    </div>
  );
};

PaymentError.propTypes = {
  withBg: PropTypes.func.isRequired
};