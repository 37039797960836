import React, { useEffect, useState } from "react";
import { Header } from "../../components/Header/Header";
import {Title} from "../../components/Title/Title";
import {Text} from "../../components/Text/Text";
import { Link } from "react-router-dom";
import {isIOS, isSafari} from "react-device-detect";
import PropTypes from "prop-types";

import "./Payment.scss";
import {GooglePay} from "./GooglePay";
import {ApplePayButton} from "./ApplePay";

export const Payment = ({ withBg, ac, dc }) => {
  const [amount, setAmount] = useState('');
  const dcValue = amount / (dc / 100)
  const acValue = amount / (ac / 100)

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  useEffect(() => {
    withBg(false)
  }, [withBg])

  return (
    <div className="content">
      <Header/>
      <Link to="/" className="button-back">
        <img src="/images/ico-back.svg" alt=""/>
        <span>Назад</span>
      </Link>
      <Title title={'Оплата заряджання'} />
      <Text text={'Lorem ipsum dolor sit amet consectetur. Iaculis netus sed nulla posuere vel aliquet sed.'} />

      <div className="input-wrap">
        <label>Сума платежу:</label>
        <div className="input">
          <div className="input-container">
            <input type="number" value={amount} onChange={handleAmountChange} />
            <span className="currency">грн</span>
          </div>
        </div>
      </div>

      <div className="amounts-box">
        <div className="amount-box">
          <div className="field">
            DC:
            <span className="field-accent">
              {/*{amount / (dc / 100)}*/}
              {Number(dcValue).toFixed(3)}
              <span>кВт</span>
            </span>
          </div>
          <div className="field-small">1 кВт = {dc / 100} ₴</div>
        </div>
        <div className="sep"></div>
        <div className="amount-box">
          <div className="field">
            AC:
            <span className="field-accent">
              {/*{amount / (ac / 100)}*/}
              {Number(acValue).toFixed(3)}
              <span>кВт</span>
            </span>
          </div>
          <div className="field-small">1 кВт = {ac / 100} ₴</div>
        </div>
      </div>

      <div className="pay-buttons">
        {/*{(isIOS && isSafari) ? <ApplePayButton moneyAmount={amount} /> : <GooglePay moneyAmount={amount} />}*/}
        <GooglePay moneyAmount={amount} />
      </div>

    </div>
  );
};

Payment.propTypes = {
  withBg: PropTypes.func.isRequired,
  ac: PropTypes.number.isRequired,
  dc: PropTypes.number.isRequired
};