import React from 'react';
import axios from 'axios';
import GooglePayButton from "@google-pay/button-react";

export const GooglePay = ({ moneyAmount }) => {
  /*const handlePayment = async () => {
    try {
      // Виконайте запит до вашого сервера з необхідними даними платежу
      const response = await axios.post('https://api-googlepay.ipay.ua', {
        // Передайте необхідні дані платежу, згідно з документацією API Google Pay
      });

      // Обробіть відповідь з вашого сервера та виконайте дії відповідно до результату платежу
      console.log(response.data);
    } catch (error) {
      // Обробіть помилку, якщо її виникло
      console.error(error);
    }
  };*/

  const handlePayment = async (paymentData) => {
    console.log(paymentData)
    try {
      const response = await axios.post('/your/token/endpoint', {
        paymentData: paymentData.paymentMethodData.tokenizationData.token,
      });
      // Опрацюйте успішну відповідь з сервера
      console.log(response.data);
    } catch (error) {
      // Опрацюйте помилку під час відправки токена на сервер
      console.error(error);
    }
  };

  return (
    <GooglePayButton
      environment="PRODUCTION"
      paymentRequest={{
        apiVersion: 2,
        apiVersionMinor: 0,
        allowedPaymentMethods: [
          {
            type: 'CARD',
            parameters: {
              allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
              allowedCardNetworks: ['MASTERCARD', 'VISA'],
            },
            tokenizationSpecification: {
              type: 'PAYMENT_GATEWAY',
              parameters: {
                gateway: 'ipayua',
                gatewayMerchantId: 'Res_GPay'
              },
            },
          },
        ],
        merchantInfo: {
          merchantId: 'BCR2DN4T4SVMV4K5',
          merchantName: 'Respublika_gpay',
        },
        transactionInfo: {
          totalPriceStatus: 'FINAL',
          totalPrice: moneyAmount,
          currencyCode: 'UAH',
        },
      }}
      buttonLocale="uk"
      buttonColor="white"
      buttonType="pay"
      buttonSizeMode="fill"
      className="google-pay-btn"
      onLoadPaymentData={handlePayment}
    />
  );
};
