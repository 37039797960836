import React, {useEffect, useState} from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from "./screens/Home/Home";
import { Payment } from "./screens/Payment/Payment";
import { PaymentSuccess } from "./screens/PaymentSuccess/PaymentSuccess";
import { PaymentError } from "./screens/PaymentError/PaymentError";
import { Charging } from "./screens/Charging/Charging";
// import {fetchData, instanceFetch} from "./services/instanceFetch";
// import { middlewareUrl } from "./services/config";

import "./App.scss";
import axios from "axios";
import {number} from "prop-types";
import {Login} from "./screens/Login/Login";

const App = () => {
  const [withBg, setWithBg] = useState(true);
  const [ac, setAc] = useState(0);
  const [dc, setDc] = useState(0);
  const [authToken, setAuthToken] = useState(false)

  function setWithoutBg(value) {
    setWithBg(value);
  }

  const styles = {
    backgroundImage: "url('/images/bg-image.svg')",
  };

  function setToken(token) {
    const expirationDate = new Date().getTime() + 24 * 60 * 60 * 1000; // 1 day
    localStorage.setItem("authToken", JSON.stringify({ value: token, expiresAt: expirationDate }));
  }

  function readToken() {
    const token = localStorage.getItem("authToken");
    // console.log('AuthToken', token)
    if (token) {
      const { value, expiresAt } = JSON.parse(token);
      if (expiresAt && new Date().getTime() < expiresAt) {
        return value;
      }
    }
    return null;
  }

  useEffect(() => {
    const authToken = readToken();
    authToken && setAuthToken(true);

    axios.get('https://backend.respublikapark.com.ua/api/e-parking/tariff')
      .then(resp => {
        setAc(resp.data.AC)
        setDc(resp.data.DC)
      })
  }, [])

  return (
    <Router>
      <div className="app">
        <div className="wrapper" style={withBg ? styles : null}>
          <Routes>
            <Route path="/" exact element={<Home withBg={setWithoutBg} ac={ac} dc={dc} authToken={authToken}/>} />
            <Route path="/login" exact element={<Login withBg={setWithoutBg} setToken={setToken} />} />
            <Route path="/payment" element={<Payment withBg={setWithoutBg} ac={ac} dc={dc}/>} />
            <Route path="/payment-success" element={<PaymentSuccess withBg={setWithoutBg}/>} />
            <Route path="/payment-error" element={<PaymentError withBg={setWithoutBg} />} />
            <Route path="/charging" element={<Charging withBg={setWithoutBg}/>} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
