import React from "react";
import { Link } from "react-router-dom";

import "./Header.scss"

export const Header = () => {
  return (
    <div className="header">
      <Link to="/">
        <img src="/images/logo.svg" className="App-logo" alt="logo" />
      </Link>
    </div>
  );
};