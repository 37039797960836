import React, { useEffect, useState } from "react";
import { Header } from "../../components/Header/Header";
import { Title } from "../../components/Title/Title";
import { Text } from "../../components/Text/Text";
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";
import QRCode from "qrcode.react";
import PropTypes from "prop-types";

import "./PaymentSuccess.scss";

export const PaymentSuccess = ({ withBg }) => {
  const navigate = useNavigate();
  const [sessionData, setSessionData] = useState(null);
  const [redirectToHomePage, setRedirectToHomePage] = useState(false);
  const [redirectToChargingPage, setRedirectToChargingPage] = useState(false);

  const auth = JSON.parse(localStorage.getItem("authToken"));
  const plainTextToken = auth ?
    auth.value.plainTextToken.split("|")[1] :
    navigate('/login');

  useEffect(() => {
    withBg(true);

    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://backend.respublikapark.com.ua/api/user/e-parking/active-session",
          {
            headers: {
              Authorization: `Bearer ${plainTextToken}`,
            },
          }
        );

        setSessionData(response.data);

        if (response.data.status === 50) {
          setRedirectToHomePage(true);
        }

        if (response.data.status === 20) {
          setRedirectToChargingPage(true);
        }
      } catch (error) {
        console.error("Error fetching session data:", error);
      }
    };

    fetchData();

    const interval = setInterval(fetchData, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [withBg, plainTextToken]);

  redirectToHomePage && navigate("/");
  redirectToChargingPage && navigate("/charging");

  return (
    <div className="content">
      <Header />
      <Title title="Оплата успішна!" />
      <div className="content-wrap">
        <Text text={"Lorem ipsum dolor sit amet consectetur. Iaculis netus sed nulla posuere vel aliquet sed. "} />
        {sessionData && sessionData.qr_unique &&
          <div className="qr-code-wrap">
            <QRCode value={sessionData.qr_unique} size={200} renderAs="svg" />
          </div>
        }
        <div className="instructions">
          <div className="instructions-head">Як користуватись?</div>
          <ul>
            <li>Lorem ipsum dolor sit amet consectetur.</li>
            <li>Lorem ipsum dolor sit amet ipsum dolor sit amet consectetur.</li>
            <li>Lorem ipsum dolor sit amet consectetur.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

PaymentSuccess.propTypes = {
  withBg: PropTypes.func.isRequired,
};

export default PaymentSuccess;
